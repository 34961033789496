import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import FilterStyles from './CoffeeKindStyles.style';

function countProductInCoffees(products) {
  const counts = products
    .map((product) => product.coffees)
    .flat()
    .reduce((prevValue, currentValue) => {
      // check if this is an existing coffees
      const existingCoffees = prevValue[currentValue.id];
      // if it is, increment by 1
      if (existingCoffees) {
        existingCoffees.count += 1;
      } else {
        // otherwise create new entry
        prevValue[currentValue.id] = {
          id: currentValue.id,
          name: currentValue.name,
          count: 1,
        };
      }

      return prevValue;
    }, {});
  // return the products with count

  // sort them based on their count
  const sortedCoffees = Object.values(counts).sort((a, b) => b.count - a.count);
  return sortedCoffees;
}

export default function CoffeeKind() {
  // get a list of ingredient
  const { products } = useStaticQuery(graphql`
    query {
      # coffeeKind: allSanityCoffee {
      #   nodes {
      #     name
      #     id
      #     # bestseller
      #   }
      # }
      products: allSanityProduct {
        nodes {
          coffees {
            name
            id
          }
        }
      }
    }
  `);

  const productsWithCounts = countProductInCoffees(products.nodes);
  // get a list of all the products with ingredient
  // count how many products are in each ingredient
  // loop over the list of ingredient and display ingredient and count of products in that ingredients
  return (
    <FilterStyles>
      <Link to="/products">
        <span>All</span>
        <span className="count">{products.nodes.length}</span>
      </Link>
      {productsWithCounts.map((product) => (
        <Link to={`/ingredient/${product.name}`} key={product.id}>
          <span>{product.name}</span>
          <span className="count">{product.count}</span>
        </Link>
      ))}
    </FilterStyles>
  );
}
