import styled from 'styled-components';
import tw from 'twin.macro';

const ProductStyles = styled.div`
  /* ${tw`w-full p-4 lg:w-1/4 md:w-1/2`} */
  ${tw`transition duration-300 transform rounded shadow-sm hover:-translate-y-2 hover:shadow`}
  /* a {
    ${tw`transition duration-300 transform rounded shadow-sm hover:-translate-y-2 hover:shadow`}
  } */
  .img-wrapper {
    ${tw`relative overflow-hidden h-72`};
    img {
      ${tw`object-cover object-center w-full h-full rounded hover:scale-75`};
    }
  }
  .content {
    ${tw`p-3 mt-4`}
    h3 {
      ${tw`mb-1 text-xs tracking-widest text-gray-500 `}
    }
    h2 {
      ${tw`text-lg font-medium text-gray-500 transition-colors group-hover:text-gray-900 group-hover:underline`}
    }
    p {
      ${tw`mt-1 text-red-600 group-hover:font-bold`};
    }
  }
`;
export default ProductStyles;
