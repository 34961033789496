import React from 'react';
import { graphql } from 'gatsby';
import CoffeeKind from '../components/CoffeeKind/CoffeeKind.component';
import Products from '../components/Products/Products.component';
import Seo from '../components/Seo.component';
import styled from 'styled-components';
import tw from 'twin.macro';

const ProductsPageStyle = styled.main`
  ${tw`container px-5 py-10 mx-auto`}
`;

function ProductPage({ data, pageContext }) {
  return (
    <ProductsPageStyle>
      <Seo
        title={
          pageContext.ingredient
            ? `Sản phẩm ${pageContext.ingredient}`
            : 'Tất cả sản phẩm'
        }
      />

      <CoffeeKind />
      <Products products={data?.products?.nodes} />
    </ProductsPageStyle>
  );
}
export const query = graphql`
  # Write your query or mutation here
  query ProductsQuery($ingredient: [String]) {
    products: allSanityProduct(
      filter: { coffees: { elemMatch: { name: { in: $ingredient } } } }
    ) {
      nodes {
        _id
        name
        price

        slug {
          current
        }
        coffees {
          name
        }
        # https://www.gatsbyjs.com/plugins/gatsby-source-sanity/#using-images
        image {
          asset {
            url
            originalFilename
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default ProductPage;
