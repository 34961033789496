import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import formatMoney from '../../utils/formatMoney';
import ProductStyles from './ProductStyles.style';
export default function SingleProduct({ product }) {
  return (
    <ProductStyles>
      <Link to={`/product/${product.slug.current}`}>
        <div className="img-wrapper">
          <GatsbyImage
            image={product.image[0].asset.gatsbyImageData}
            alt={product.image[0].asset.originalFilename}
          />
        </div>
        <div className="content">
          <h3>{product.coffees.map((coffee) => coffee.name).join(', ')}</h3>
          <h2>{product.name}</h2>

          <p>{formatMoney(product.price)}</p>
        </div>
      </Link>
    </ProductStyles>
    // <ProductStyles>
    //   <Link to={`/product/${product.slug.current}`}>
    //     <h2>
    //       <span className="mark">{product.name}</span>
    //     </h2>
    //   </Link>
    //   <p>{product.coffees.map((coffee) => coffee.name).join(', ')}</p>

    //   <GatsbyImage
    //     image={product.image[0].asset.gatsbyImageData}
    //     alt={product.image[0].asset.originalFilename}
    //   />
    // </ProductStyles>
  );
}
