import React from 'react';
import Product from './Product.component';
import styled from 'styled-components';
import tw from 'twin.macro';

const ProductsWrapper = styled.div`
  /* ${tw`flex flex-wrap py-8 -m-4`}; */
  ${tw`grid gap-8 py-5 sm:mx-auto lg:grid-cols-4`}
`;

export default function ProductsList({ products }) {
  return (
    <ProductsWrapper>
      {products.map((product) => (
        <Product key={product._id} product={product} />
      ))}
    </ProductsWrapper>
  );
}
