import styled from 'styled-components';
import tw from 'twin.macro';

const FilterStyles = styled.div`
  ${tw`flex flex-wrap items-center gap-2`};

  a {
    ${tw`flex items-center p-1 text-sm bg-white border rounded text-primary border-primary hover:bg-primary_light`}
    span {
      ${tw`text-sm underline`}
      &.count {
        ${tw`flex items-center justify-center w-6 h-6 p-2 ml-2 no-underline bg-white border rounded-full border-primary_light text-primary`}
      }
    }

    &[aria-current='page'] {
      ${tw`text-white bg-primary`}
    }
  }
`;

export default FilterStyles;
